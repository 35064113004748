<template>
    <div id="header-text">
        <span id="program-list" class="pi pi-list"></span>
        <h1>{{ program.title }}</h1>
        <h2 v-html="program.subtitle"></h2>
    </div>
    <div class="schedule-container">
        <div class="day-menu">
            <div v-for="(day, index) in weekDays" :key="index" @click="selectDay(index)"
                :class="{ active: selectedDay === index }">
                {{ day }}
            </div>
        </div>
        <div class="lecture-program">
            <div v-for="group in groupedLectures" :key="group.startTime" class="lecture-group">
                <div class="time-marker">{{ group.startTime }}</div>
                <div class="lectures">
                    <div v-for="lecture in group.lectures" :key="lecture.id" class="lecture-item">
                        <div class="lecture-content">
                            <h3>{{ lecture.title }}</h3>
                            <p>
                                <span v-if="lecture.room" id="lecture-room">{{
                                    lecture.room
                                }}</span><span class="pi pi-clock"></span><span id="lecture-time">{{
                                        lecture.startTime }} - {{ lecture.endTime }}</span>
                            </p>
                            <p class="lecture-description">{{ lecture.description }}</p>
                            <div class="speaker-info">
                                <span v-for="speaker in group.lectures[0].speaker" :key="speaker.name">
                                    <img :src="speaker.image" alt="Speaker Image" class="speaker-image" />
                                    <span class="speaker-name">
                                        {{ speaker.name }}
                                    </span>
                                </span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: "LectureSchedule",
    props: {
        program: Object,
    },
    data() {
        return {
            selectedDay: 0,
            weekDays: this.program.weekDays,
            lectureSchedule: this.program.lectureSchedule,
        };
    },
    computed: {
        groupedLectures() {
            const lecturesByDay = this.lectureSchedule.filter(
                (lecture) => lecture.day === this.selectedDay
            );
            const groups = {};

            lecturesByDay.forEach((lecture) => {
                if (!groups[lecture.startTime]) {
                    groups[lecture.startTime] = [];
                }
                groups[lecture.startTime].push(lecture);
            });

            return Object.keys(groups).map((startTime) => ({
                startTime,
                lectures: groups[startTime],
            }));
        },
    },
    methods: {
        selectDay(index) {
            this.selectedDay = index;
        },
    },
};
</script>

<style scoped>
#header-text {
    display: block;
    text-align: center;
    padding-bottom: 30px;
}

#program-list {
    font-size: 55px !important;
    color: rgb(255, 168, 0);
}

#header-text h2 {
    color: #b8bcc3;
    line-height: normal;
    font-weight: 400;
    font-size: 15px;
}

.schedule-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 90%;
    margin-left: 5%;
}

.day-menu {
    display: flex;
    justify-content: center;
    margin-bottom: 20px;
    font-size: 18px;
    font-weight: bold;
}

.day-menu>div {
    padding: 10px;
    cursor: pointer;
    border: 0.5px solid #b8bcc3;
    border-radius: 7px;
    margin: 2px;
}

.day-menu>div:hover {
    color: white;
    background-color: rgb(255, 168, 0);
}

.day-menu>div.active {
    color: white;
    background-color: rgb(255, 168, 0);
    border: 0;
}

.lecture-program {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
}

.lecture-description {
    text-align: justify;
}

.lecture-group {
    display: flex;
    margin-bottom: 20px;
    width: 100%;
}

.time-marker {
    width: 100px;
    height: auto;
    margin-right: 10px;
    background-color: rgb(255, 168, 0);
    border: 1px solid #e9e9e9;
    display: flex;
    align-items: center;
    justify-content: center;
    color: white;
    font-weight: bold;
    border-radius: 2px;
}

.lectures {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
}

#lecture-room {
    color: rgb(255, 168, 0);
    margin-right: 5px;
}

#lecture-time,
.pi-clock {
    margin-left: 5px;
    color: #b8bcc3;
    margin-bottom: 15px;
}

.lecture-item {
    border: 1px solid #e9e9e9;
    border-radius: 2px;
    padding: 15px;
    margin-bottom: 10px;
    width: 100%;
}

.lecture-content {
    text-align: left;
}

h3 {
    color: rgb(38, 38, 39);
    margin-bottom: 5px;
}

.speaker-info {
    display: flex;
    margin-top: 20px;

}

.speaker-name {
    font-weight: 501;
    float: right;
    padding-top: 20px;
    margin-right: 10px;
}

.speaker-image {
    width: 50px;
    height: 50px;
    border-radius: 50%;
    margin-right: 10px;
    border: 1px solid rgb(255, 168, 0);
}

@media only screen and (max-width: 900px) {
    .lecture-group {
        flex-direction: column;
        align-items: flex-start;
        margin-left: 10%;
    }

    .time-marker {
        width: 90%;
        height: 40px;
        margin-right: 0;
    }

    .lecture-item {
        width: 90%;
    }

    .speaker-name {
        font-weight: 501;
        float: left;

        
    }

    .speaker-image {
        display: block;
        float: left;
        text-align: right;
    }


}
</style>
